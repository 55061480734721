<template>
  <v-col cols="12" :lg="getWidth" md="12" sm="12">
    <v-card
      :outlined="!item.RemoveCard"
      :color="item.RemoveCard ? 'transparent' : ''"
      :elevation="item.RemoveCard ? '0' : ''"
      height="100%"
    >
      <v-card-text :class="item.RemoveCard ? 'pa-0' : ''">
        <!-- Add title -->
        <template v-if="editMode">
          <v-layout class="mb-4">
            <div class="mr-4">
              <v-layout column>
                <v-btn
                  depressed
                  text
                  small
                  icon
                  color="grey"
                  v-on:click="$emit('moveUpNonRepPage', objectName)"
                >
                  <v-icon>mdi-chevron-up</v-icon>
                </v-btn>

                <v-btn
                  depressed
                  text
                  small
                  icon
                  color="grey"
                  v-on:click="$emit('moveDownNonRepPage', objectName)"
                >
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </v-layout>
            </div>
            <div class="full-width d-flex align-center">
              <configLanguageSelector
                v-if="item.TranslatedTitle"
                :itemData="item"
                @changeTranslatedTitle="item.TranslatedTitle = $event"
              />
              <v-text-field
                v-else
                :label="objectName"
                v-model="item.Title"
                outlined
                dense
                hide-details="auto"
              ></v-text-field>
            </div>
            <div class="text-right d-flex align-center">
              <v-btn
                depressed
                color="primaryred"
                icon
                v-on:click="deleteItem()"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </v-layout>
        </template>
        <template v-else>
          <v-layout justify-space-between>
            <h3 class="mb-3" v-if="getItemTitle">{{ getItemTitle }}</h3>
            <edit-read-only-item-switch
              v-if="userIsAdministrator && (item.ReadOnly || needsDisable)"
              @update:editItem="editItemAdmin = $event"
            />
          </v-layout>
        </template>

        <!-- If the item is text -->
        <page-element-text
          v-if="item.Type === 'text'"
          :item="item"
          :needsDisable="getNeedsDisable"
          :editItemAdmin="editItemAdmin"
        />
        <!-- If the item is number -->
        <page-element-number
          v-else-if="item.Type === 'number'"
          :item="item"
          :needsDisable="getNeedsDisable"
          :editItemAdmin="editItemAdmin"
        />
        <!-- If the item is rich text -->
        <page-element-richtext
          v-else-if="item.Type === 'richtext'"
          :item="item"
          :needsDisable="getNeedsDisable"
          :editItemAdmin="editItemAdmin"
        />

        <!-- If the item is a boolean -->
        <page-element-boolean
          v-else-if="item.Type === 'boolean'"
          :item="item"
          :needsDisable="getNeedsDisable"
          :editItemAdmin="editItemAdmin"
        />

        <!-- If the item is a date picker -->
        <page-element-datepicker
          v-else-if="item.Type === 'datepicker'"
          :item="item"
          :needsDisable="getNeedsDisable"
          :editItemAdmin="editItemAdmin"
        />

        <!-- If the item is time -->
        <page-element-timepicker
          v-else-if="item.Type === 'time'"
          :item="item"
          :needsDisable="getNeedsDisable"
          :editItemAdmin="editItemAdmin"
        />

        <!-- If the item is dropdown -->
        <page-element-dropdown
          v-else-if="item.Type === 'dropdown'"
          :item="item"
          :needsDisable="getNeedsDisable"
          :editItemAdmin="editItemAdmin"
        />

        <!-- If the item is a list of content -->
        <page-element-content
          v-else-if="item.Type === 'content'"
          :item="item"
          :needsDisable="getNeedsDisable"
          :editItemAdmin="editItemAdmin"
          :pageId="pageId"
        />

        <!-- If the item is an image -->
        <page-element-image
          v-else-if="item.Type === 'image'"
          :item="item"
          :needsDisable="getNeedsDisable"
          :editItemAdmin="editItemAdmin"
          :websiteId="websiteId"
          :pageId="pageId"
          :objectName="objectName"
          @update:saving="$emit('update:saving', $event)"
          @setUploadError="setError($event)"
        />

        <!-- If the item is a cropped image -->
        <page-element-croppedimage
          v-else-if="item.Type === 'croppedimage'"
          :item="item"
          :needsDisable="getNeedsDisable"
          :editItemAdmin="editItemAdmin"
          :websiteId="websiteId"
          :pageId="pageId"
          :objectName="objectName"
          @update:saving="$emit('update:saving', $event)"
        />

        <!-- If the item is an image gallery -->
        <page-element-gallery
          v-else-if="item.Type === 'gallery'"
          :item="item"
          :needsDisable="getNeedsDisable"
          :editItemAdmin="editItemAdmin"
          :websiteId="websiteId"
          :pageId="pageId"
          :objectName="objectName"
          :uploadError="uploadError"
          @update:saving="$emit('update:saving', $event)"
          @setUploadError="setError($event)"
        />

        <!-- If the item is a list of files -->
        <page-element-files
          v-else-if="item.Type === 'files'"
          :item="item"
          :needsDisable="getNeedsDisable"
          :editItemAdmin="editItemAdmin"
          :websiteId="websiteId"
          :pageId="pageId"
          :objectName="objectName"
          :uploadError="uploadError"
          @update:saving="$emit('update:saving', $event)"
          @setUploadError="setError($event)"
        />

        <!-- If the item is a list of custom -->
        <page-element-custom
          v-else-if="item.Type === 'custom'"
          :item="item"
          :objectName="objectName"
        />

        <!-- If the item is colorpicker -->
        <page-element-colorpicker
          v-else-if="item.Type === 'colorpicker'"
          :item="item"
          :needsDisable="getNeedsDisable"
          :editItemAdmin="editItemAdmin"
        />

        <!-- If the item is code field -->
        <page-element-codefield
          v-else-if="item.Type === 'codefield'"
          :item="item"
          :needsDisable="getNeedsDisable"
          :editItemAdmin="editItemAdmin"
        />

        <!-- If the item is url -->
        <page-element-url
          v-else-if="item.Type === 'url'"
          :item="item"
          :needsDisable="getNeedsDisable"
          :editItemAdmin="editItemAdmin"
        />
        <!-- If the item is url -->
        <page-element-qrcode
          v-else-if="item.Type === 'qrcode'"
          :item="item"
          :needsDisable="getNeedsDisable"
          :editItemAdmin="editItemAdmin"
        />
        <!-- If the item is url -->
        <page-element-htmlobject
          v-else-if="item.Type === 'htmlobject'"
          :item="item"
          :needsDisable="getNeedsDisable"
          :editItemAdmin="editItemAdmin"
        />
      </v-card-text>
    </v-card>
  </v-col>
</template>
<script>
import moment from "moment";

export default {
  data() {
    return {
      uploadError: "",
      userIsAdministrator: this.$auth.userIsAdministrator(),
      needsDisable: this.$route.path.includes("detail"),
      editItemAdmin: false,
    };
  },
  props: [
    "editMode",
    "item",
    "websiteId",
    "pageId",
    "objectName",
    "colsWidth",
    "isBulk",
  ],
  created() {
    const hasWrite = this.$store.getters.hasUserRoleForPageItem({
      role: 1,
    });
    if (!hasWrite) {
      this.needsDisable = true;
    }
    if (this.userIsAdministrator) {
      this.needsDisable = false;
    }
  },
  computed: {
    getNeedsDisable: {
      get() {
        return this.needsDisable;
      },
    },
    currentLanguage: {
      get: function () {
        return this.$i18n.locale();
      },
    },
    getItemTitle() {
      if (this.item.TranslatedTitle) {
        if (
          this.item.TranslatedTitle[this.currentLanguage] == "" ||
          !this.item.TranslatedTitle[this.currentLanguage]
        ) {
          return this.item.TranslatedTitle["Nl"];
        } else {
          return this.item.TranslatedTitle[this.currentLanguage];
        }
      }
      return this.item.Title;
    },
    getWidth() {
      if (this.isBulk) {
        return 12;
      }
      return this.item.colWidth;
    },
  },
  watch: {
    "item.Value": {
      handler(val) {
        switch (this.item.Type) {
          case "datepicker":
            this.item.ValueSort = parseInt(moment(val).format("YYYYMMDD"));
            break;
          case "richtext":
            this.item.ValueSort = val[this.currentLanguage].replace(
              /<[^>]*>?/gm,
              ""
            );
            break;
          case "dropdown":
            if (!val) return;
            this.item.ValueSort = this.item.Options.find(
              (o) => o.Id == val
            ).Value;
            break;
        }
      },
      deep: true,
    },
  },
  methods: {
    deleteItem() {
      this.$emit("removeItem");
    },
    setError(event) {
      if (event) {
        this.uploadError = event;
        const toaster = {
          message: event,
          timeout: false,
          type: "ERROR",
          toastClassName: ["toaster-color-error", "toaster-layout"],
          bodyClassName: ["toaster-text-layout"],
          icon: "mdi-alert-octagon",
        };
        this.$store.dispatch("setToaster", toaster);
      }
    },
  },
};
</script>
